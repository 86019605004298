import { combineReducers } from 'redux';
import {
    ASSET_FETCH_SUCCESS,
    COLLECTION_SET,
    CREATE_COLLECTION_DIALOG_HIDE,
    DATE_RANGE_SET,
    EMPTY_VALUES_SET,
    END_DATE_SET,
    END_TIME_SET,
    EXTENSIBLE_STATUS_SET,
    NSFW_STATUS_SET,
    RECIEPENT_ADDRESS_STATUS_SET,
    RECIPIENT_ADDRESS_SET,
    ROYALTY_SHARE_SET,
    ROYALTY_SHARE_STATUS_SET,
    SCHEMA_VALUES_SET,
    START_DATE_SET,
    START_TIME_SET,
    TOKEN_PRICE_SET,
    TOKEN_VALUE_SET,
    TRANSFER_STATUS_SET,
} from '../../constants/createAssets';
import {
    ALL_COLLECTIONS_FETCH_ERROR,
    ALL_COLLECTIONS_FETCH_IN_PROGRESS,
    ALL_COLLECTIONS_FETCH_SUCCESS,
    BURN_NFT_CONFIRM_DIALOG_HIDE,
    BURN_NFT_CONFIRM_DIALOG_SHOW,
    CANCEL_FETCH_ALL_COLLECTIONS,
    CANCEL_FETCH_COLLECTIONS,
    COLLECTION_CONFIRM_DIALOG_HIDE,
    COLLECTION_CONFIRM_DIALOG_SHOW,
    COLLECTION_FETCH_ERROR,
    COLLECTION_FETCH_IN_PROGRESS,
    COLLECTION_FETCH_SUCCESS,
    COLLECTIONS_FETCH_ERROR,
    COLLECTIONS_FETCH_IN_PROGRESS,
    COLLECTIONS_FETCH_SUCCESS,
    FETCH_LISTED_COLLECTION_NFT_S_ERROR,
    FETCH_LISTED_COLLECTION_NFT_S_IN_PROGRESS,
    FETCH_LISTED_COLLECTION_NFT_S_SUCCESS,
    FETCH_NON_LISTED_COLLECTION_NFT_S_ERROR,
    FETCH_NON_LISTED_COLLECTION_NFT_S_IN_PROGRESS,
    FETCH_NON_LISTED_COLLECTION_NFT_S_SUCCESS,
    FETCH_NON_TRANSFERABLE_COLLECTION_NFT_S_ERROR,
    FETCH_NON_TRANSFERABLE_COLLECTION_NFT_S_IN_PROGRESS,
    FETCH_NON_TRANSFERABLE_COLLECTION_NFT_S_SUCCESS,
    MINT_NFT_ADD_ERROR,
    MINT_NFT_ADD_IN_PROGRESS,
    MINT_NFT_ADD_SUCCESS,
    MINT_NFT_CONFIRM_DIALOG_HIDE,
    MINT_NFT_CONFIRM_DIALOG_SHOW,
    MINT_NFT_ERROR,
    MINT_NFT_IN_PROGRESS,
    MINT_NFT_SUCCESS,
    NEW_COLLECTION_DATA_SET,
    NFT_FETCH_ERROR,
    NFT_FETCH_IN_PROGRESS,
    NFT_FETCH_SUCCESS,
    NFT_LIST_FETCH_ERROR,
    NFT_LIST_FETCH_IN_PROGRESS,
    NFT_LIST_FETCH_SUCCESS,
    NFT_LIST_OWNED_FETCH_ERROR,
    NFT_LIST_OWNED_FETCH_IN_PROGRESS,
    NFT_LIST_OWNED_FETCH_SUCCESS,
    TRANSFER_NFT_CONFIRM_DIALOG_HIDE,
    TRANSFER_NFT_CONFIRM_DIALOG_SHOW,
} from '../../constants/createAssets/mintNft';
import { DISCONNECT_SET, TX_HASH_FETCH_SUCCESS, TX_HASH_IN_PROGRESS_FALSE_SET } from '../../constants/account';
import { tokensList } from '../../utils/defaultOptions';
import { DEFAULT_LIMIT, DEFAULT_SEARCH, DEFAULT_SKIP, DEFAULT_TOTAL } from '../../config';
import { LIST_NFT_DIALOG_HIDE, LISTINGS_FETCH_SUCCESS } from '../../constants/createAssets/listing';
import { BULK_LIST_DIALOG_HIDE, CONFIRM_BULK_LIST_DIALOG_HIDE, MENU_ACTION_DIALOG_HIDE } from '../../constants/library';
import { FETCH_NFT_SUCCESS } from '../../constants/auctions';
import { VIDEO_DROP_FIELDS_CLEAR } from '../../constants/videoDrops';

const collection = (state = {
    value: null,
    options: [],
    inProgress: false,
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    search: DEFAULT_SEARCH,
    total: null,
    cancel: null,
}, action) => {
    switch (action.type) {
    case COLLECTION_SET:
        return {
            ...state,
            value: action.value,
        };
    case COLLECTIONS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case COLLECTIONS_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                options: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                search: action.search,
                total: action.total,
                cancel: null,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                options: [...state.options, ...action.value],
                skip: action.skip,
                limit: action.limit,
                search: action.search,
                total: action.total,
                cancel: null,
            };
        }
    case COLLECTIONS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
            cancel: null,
        };
    case CANCEL_FETCH_COLLECTIONS:
        return {
            ...state,
            cancel: action.cancel,
        };
    case EMPTY_VALUES_SET:
    case ASSET_FETCH_SUCCESS:
        return {
            ...state,
            value: null,
        };
    case DISCONNECT_SET:
        return {
            value: null,
            options: [],
            inProgress: false,
            skip: DEFAULT_SKIP,
            limit: DEFAULT_LIMIT,
            search: DEFAULT_SEARCH,
            total: null,
            cancel: null,
        };
    default:
        return state;
    }
};

const allCollections = (state = {
    value: null,
    options: [],
    inProgress: false,
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    search: DEFAULT_SEARCH,
    total: null,
    cancel: null,
}, action) => {
    switch (action.type) {
    case COLLECTION_SET:
        return {
            ...state,
            value: action.value,
        };
    case ALL_COLLECTIONS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ALL_COLLECTIONS_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                options: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                search: action.search,
                total: action.total,
                cancel: null,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                options: [...state.options, ...action.value],
                skip: action.skip,
                limit: action.limit,
                search: action.search,
                total: action.total,
                cancel: null,
            };
        }
    case ALL_COLLECTIONS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
            cancel: null,
        };
    case CANCEL_FETCH_ALL_COLLECTIONS:
        return {
            ...state,
            cancel: action.cancel,
        };
    case EMPTY_VALUES_SET:
        return {
            ...state,
            value: null,
            cancel: null,
        };
    case DISCONNECT_SET:
        return {
            value: null,
            options: [],
            inProgress: false,
            skip: DEFAULT_SKIP,
            limit: DEFAULT_LIMIT,
            search: DEFAULT_SEARCH,
            total: null,
            cancel: null,
        };
    default:
        return state;
    }
};

const singleCollection = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case COLLECTION_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case COLLECTION_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case COLLECTION_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const recipientAddress = (state = '', action) => {
    switch (action.type) {
    case RECIPIENT_ADDRESS_SET:
        return action.value;
    case TX_HASH_FETCH_SUCCESS:
    case TX_HASH_IN_PROGRESS_FALSE_SET:
    case EMPTY_VALUES_SET:
    case MENU_ACTION_DIALOG_HIDE:
        return '';
    default:
        return state;
    }
};

const newCollection = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case NEW_COLLECTION_DATA_SET:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case CREATE_COLLECTION_DIALOG_HIDE:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const transferStatus = (state = true, action) => {
    switch (action.type) {
    case TRANSFER_STATUS_SET:
        return action.value;
    case TX_HASH_FETCH_SUCCESS:
    case TX_HASH_IN_PROGRESS_FALSE_SET:
    case EMPTY_VALUES_SET:
    case MENU_ACTION_DIALOG_HIDE:
        return true;
    default:
        return state;
    }
};

const extensibleStatus = (state = true, action) => {
    switch (action.type) {
    case EXTENSIBLE_STATUS_SET:
        return action.value;
    case TX_HASH_FETCH_SUCCESS:
    case TX_HASH_IN_PROGRESS_FALSE_SET:
    case EMPTY_VALUES_SET:
    case MENU_ACTION_DIALOG_HIDE:
        return true;
    default:
        return state;
    }
};

const nsfwStatus = (state = false, action) => {
    switch (action.type) {
    case NSFW_STATUS_SET:
        return action.value;
    case TX_HASH_FETCH_SUCCESS:
    case TX_HASH_IN_PROGRESS_FALSE_SET:
    case EMPTY_VALUES_SET:
    case MENU_ACTION_DIALOG_HIDE:
        return false;
    default:
        return state;
    }
};

const royaltyShare = (state = {
    status: false,
    value: '',
}, action) => {
    switch (action.type) {
    case ROYALTY_SHARE_STATUS_SET:
        return {
            ...state,
            status: action.value,
        };
    case ROYALTY_SHARE_SET:
        return {
            ...state,
            value: action.value,
        };
    case TX_HASH_FETCH_SUCCESS:
    case TX_HASH_IN_PROGRESS_FALSE_SET:
    case EMPTY_VALUES_SET:
    case MENU_ACTION_DIALOG_HIDE:
        return {
            status: false,
            value: '',
        };
    default:
        return state;
    }
};

// studio api mint nft
const mintNft = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case MINT_NFT_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case MINT_NFT_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case MINT_NFT_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

// BC mint nft
const newMintNft = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case MINT_NFT_ADD_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case MINT_NFT_ADD_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case MINT_NFT_ADD_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case EMPTY_VALUES_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const nftList = (state = {
    inProgress: false,
    value: [],
}, action) => {
    switch (action.type) {
    case NFT_LIST_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case NFT_LIST_FETCH_SUCCESS: {
        const array = [...state.value];
        if (array && array.length && action.value && action.value.denom && action.value.denom.id) {
            array.map((val, index) => {
                if (val.denom && (val.denom.id === action.value.denom.id)) {
                    array.splice(index, 1);
                }

                return null;
            });
        }
        array.push(action.value);

        return {
            inProgress: false,
            value: [...array],
        };
    }
    case NFT_LIST_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case COLLECTIONS_FETCH_SUCCESS:
        return {
            ...state,
            value: [],
        };
    case DISCONNECT_SET:
        return {
            inProgress: false,
            value: [],
        };
    default:
        return state;
    }
};

const nftListOwned = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    search: DEFAULT_SEARCH,
    total: null,
}, action) => {
    switch (action.type) {
    case NFT_LIST_OWNED_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case NFT_LIST_OWNED_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: [...action.value],
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                search: action.search,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                skip: action.skip,
                limit: action.limit,
                search: action.search,
                total: action.total,
            };
        }
    case FETCH_NFT_SUCCESS: {
        const value = [...state.value];
        const index = value.findIndex((val) => action.value && val.id === action.value.id);
        if (index > -1 && value[index]) {
            value.splice(index, 1, action.value);
        }

        return {
            ...state,
            value: value,
        };
    }
    case NFT_LIST_OWNED_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            inProgress: false,
            value: [],
            skip: DEFAULT_SKIP,
            limit: DEFAULT_LIMIT,
            search: DEFAULT_SEARCH,
            total: null,
        };
    default:
        return state;
    }
};

const nft = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case NFT_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case NFT_FETCH_SUCCESS: {
        return {
            inProgress: false,
            value: action.value,
        };
    }
    case NFT_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case EMPTY_VALUES_SET:
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const schemaValues = (state = {
    value: {},
    valid: true,
}, action) => {
    switch (action.type) {
    case SCHEMA_VALUES_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case EMPTY_VALUES_SET:
    case MINT_NFT_SUCCESS:
    case VIDEO_DROP_FIELDS_CLEAR:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const recipientAddressStatus = (state = false, action) => {
    switch (action.type) {
    case RECIEPENT_ADDRESS_STATUS_SET:
        return action.value;
    case TX_HASH_FETCH_SUCCESS:
    case TX_HASH_IN_PROGRESS_FALSE_SET:
    case EMPTY_VALUES_SET:
    case MENU_ACTION_DIALOG_HIDE:
        return false;
    default:
        return state;
    }
};

const tokenValue = (state = {
    value: tokensList[0],
    options: tokensList,
}, action) => {
    switch (action.type) {
    case TOKEN_VALUE_SET:
        return {
            ...state,
            value: action.value,
        };
    case LISTINGS_FETCH_SUCCESS:
    case LIST_NFT_DIALOG_HIDE:
    case BULK_LIST_DIALOG_HIDE:
    case CONFIRM_BULK_LIST_DIALOG_HIDE:
        return {
            ...state,
            value: null,
        };
    default:
        return state;
    }
};

const tokenPrice = (state = '', action) => {
    switch (action.type) {
    case TOKEN_PRICE_SET:
        return action.value;
    case LISTINGS_FETCH_SUCCESS:
    case LIST_NFT_DIALOG_HIDE:
    case BULK_LIST_DIALOG_HIDE:
    case CONFIRM_BULK_LIST_DIALOG_HIDE:
        return '';
    default:
        return state;
    }
};

const dateRange = (state = '', action) => {
    if (action.type === DATE_RANGE_SET) {
        return action.value;
    }

    return state;
};

const startDate = (state = null, action) => {
    if (action.type === START_DATE_SET) {
        return action.value;
    }

    return state;
};

const endDate = (state = null, action) => {
    if (action.type === END_DATE_SET) {
        return action.value;
    }

    return state;
};

const startTime = (state = new Date(), action) => {
    if (action.type === START_TIME_SET) {
        return action.value;
    }

    return state;
};

const endTime = (state = new Date(), action) => {
    if (action.type === END_TIME_SET) {
        return action.value;
    }

    return state;
};

const collectionConfirmDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case COLLECTION_CONFIRM_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case COLLECTION_CONFIRM_DIALOG_HIDE:
        return {
            open: false,
            value: {},
        };
    default:
        return state;
    }
};

const mintNFTConfirmDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case MINT_NFT_CONFIRM_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case MINT_NFT_CONFIRM_DIALOG_HIDE:
        return {
            open: false,
            value: {},
        };
    default:
        return state;
    }
};

const transferNFTConfirmDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case TRANSFER_NFT_CONFIRM_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case TRANSFER_NFT_CONFIRM_DIALOG_HIDE:
    case MENU_ACTION_DIALOG_HIDE:
        return {
            open: false,
            value: {},
        };
    default:
        return state;
    }
};

const burnNFTConfirmDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case BURN_NFT_CONFIRM_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case BURN_NFT_CONFIRM_DIALOG_HIDE:
    case MENU_ACTION_DIALOG_HIDE:
        return {
            open: false,
            value: {},
        };
    default:
        return state;
    }
};

const listedCollectionNFTs = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: DEFAULT_TOTAL,
}, action) => {
    switch (action.type) {
    case FETCH_LISTED_COLLECTION_NFT_S_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_LISTED_COLLECTION_NFT_S_SUCCESS: {
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                result: [...state.result, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
            };
        }
    }
    case FETCH_LISTED_COLLECTION_NFT_S_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            inProgress: false,
            result: [],
            skip: DEFAULT_SKIP,
            limit: DEFAULT_LIMIT,
            total: DEFAULT_TOTAL,
        };
    default:
        return state;
    }
};

const nonListedCollectionNFTs = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: DEFAULT_TOTAL,
}, action) => {
    switch (action.type) {
    case FETCH_NON_LISTED_COLLECTION_NFT_S_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_NON_LISTED_COLLECTION_NFT_S_SUCCESS: {
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                result: [...state.result, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
            };
        }
    }
    case FETCH_NON_LISTED_COLLECTION_NFT_S_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            inProgress: false,
            result: [],
            skip: DEFAULT_SKIP,
            limit: DEFAULT_LIMIT,
            total: DEFAULT_TOTAL,
        };
    default:
        return state;
    }
};

const nonTransferableCollectionNFTs = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: DEFAULT_TOTAL,
}, action) => {
    switch (action.type) {
    case FETCH_NON_TRANSFERABLE_COLLECTION_NFT_S_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_NON_TRANSFERABLE_COLLECTION_NFT_S_SUCCESS: {
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                result: [...state.result, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
            };
        }
    }
    case FETCH_NON_TRANSFERABLE_COLLECTION_NFT_S_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            inProgress: false,
            result: [],
            skip: DEFAULT_SKIP,
            limit: DEFAULT_LIMIT,
            total: DEFAULT_TOTAL,
        };
    default:
        return state;
    }
};

export default combineReducers({
    collection,
    allCollections,
    singleCollection,
    recipientAddress,
    newCollection,
    transferStatus,
    extensibleStatus,
    nsfwStatus,
    royaltyShare,
    mintNft,
    newMintNft,
    nftList,
    nftListOwned,
    nft,
    schemaValues,
    recipientAddressStatus,
    tokenValue,
    tokenPrice,
    dateRange,
    startDate,
    endDate,
    startTime,
    endTime,
    collectionConfirmDialog,
    transferNFTConfirmDialog,
    burnNFTConfirmDialog,
    mintNFTConfirmDialog,
    listedCollectionNFTs,
    nonListedCollectionNFTs,
    nonTransferableCollectionNFTs,
});
