export const PARTICIPATION_NFT_SWITCH_SET = 'PARTICIPATION_NFT_SWITCH_SET';
export const TOTAL_UNITS_SET = 'TOTAL_UNITS_SET';
export const INITIAL_NFT_INDEX_VALUE_SET = 'INITIAL_NFT_INDEX_VALUE_SET';
export const VIDEO_DROPS_COUNTER_SWITCH_SET = 'VIDEO_DROPS_COUNTER_SWITCH_SET';
export const DENOM_ID_VALUE_SET = 'DENOM_ID_VALUE_SET';
export const CREATOR_VALUE_SET = 'CREATOR_VALUE_SET';
export const VIDEO_DROPS_NAME_SET = 'VIDEO_DROPS_NAME_SET';
export const VIDEO_DROPS_DESCRIPTION_SET = 'VIDEO_DROPS_DESCRIPTION_SET';
export const VIDEO_DROPS_ROYALTY_SWITCH_SET = 'VIDEO_DROPS_ROYALTY_SWITCH_SET';
export const ROYALITY_SHARE_VALUE_SET = 'ROYALITY_SHARE_VALUE_SET';
export const VIDEO_DROPS_TRANSFERABILITY_SWITCH_SET = 'VIDEO_DROPS_TRANSFERABILITY_SWITCH_SET';
export const VIDEO_DROPS_EXTENSIBILITY_SWITCH_SET = 'VIDEO_DROPS_EXTENSIBILITY_SWITCH_SET';
export const VIDEO_DROPS_NSFW_SWITCH_SET = 'VIDEO_DROPS_NSFW_SWITCH_SET';
export const VIDEO_DROPS_TWEET_TEXT_SET = 'VIDEO_DROPS_TWEET_TEXT_SET';
export const VIDEO_DROPS_PREVIEW_URL_SET = 'VIDEO_DROPS_PREVIEW_URL_SET';
export const VIDEO_DROPS_MEDIA_URL_SET = 'VIDEO_DROPS_MEDIA_URL_SET';

export const VIDEO_DROPS_COLLECTIONS_FETCH_IN_PROGRESS = 'VIDEO_DROPS_COLLECTIONS_FETCH_IN_PROGRESS';
export const VIDEO_DROPS_COLLECTIONS_FETCH_SUCCESS = 'VIDEO_DROPS_COLLECTIONS_FETCH_SUCCESS';
export const VIDEO_DROPS_COLLECTIONS_FETCH_ERROR = 'VIDEO_DROPS_COLLECTIONS_FETCH_ERROR';
export const VIDEO_DROPS_CANCEL_FETCH_COLLECTIONS = 'VIDEO_DROPS_CANCEL_FETCH_COLLECTIONS';

export const NFT_SELECT_COLLECTIONS_SET = 'NFT_SELECT_COLLECTIONS_SET';
export const PARTICIPATION_NFT_FETCH_IN_PROGRESS = 'PARTICIPATION_NFT_FETCH_IN_PROGRESS';
export const PARTICIPATION_NFT_FETCH_SUCCESS = 'PARTICIPATION_NFT_FETCH_SUCCESS';
export const PARTICIPATION_NFT_FETCH_ERROR = 'PARTICIPATION_NFT_FETCH_ERROR';

export const PARTICIPATION_NFT_AUTHZ_ADDRESS_FETCH_IN_PROGRESS = 'PARTICIPATION_NFT_AUTHZ_ADDRESS_FETCH_IN_PROGRESS';
export const PARTICIPATION_NFT_AUTHZ_ADDRESS_FETCH_SUCCESS = 'PARTICIPATION_NFT_AUTHZ_ADDRESS_FETCH_SUCCESS';
export const PARTICIPATION_NFT_AUTHZ_ADDRESS_FETCH_ERROR = 'PARTICIPATION_NFT_AUTHZ_ADDRESS_FETCH_ERROR';

export const VIDEO_DROPS_FIELDS_CLEAR_SET = 'VIDEO_DROPS_FIELDS_CLEAR_SET';
