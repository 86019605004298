import { combineReducers } from 'redux';
import {
    QUERY_VIDEO_DROPS_LIST_ERROR,
    QUERY_VIDEO_DROPS_LIST_IN_PROGRESS,
    QUERY_VIDEO_DROPS_LIST_SUCCESS,
    VIDEO_DROPS_EDIT_SET,
} from '../../constants/queryVideoDrops';

const videoDropsList = (state = {
    value: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case QUERY_VIDEO_DROPS_LIST_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case QUERY_VIDEO_DROPS_LIST_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case QUERY_VIDEO_DROPS_LIST_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const videoDropEdit = (state = {
    value: [],
    open: false,
}, action) => {
    switch (action.type) {
    case VIDEO_DROPS_EDIT_SET:
        return {
            value: action.value,
            open: action.open,
        };
    default:
        return state;
    }
};

export default combineReducers({
    videoDropsList,
    videoDropEdit,
});
