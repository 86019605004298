import { combineReducers } from 'redux';
import {
    COLLECTION_EXPORT_POPOVER_HIDE,
    COLLECTION_EXPORT_POPOVER_SHOW,
    COLLECTION_STATS_FETCH_ERROR,
    COLLECTION_STATS_FETCH_IN_PROGRESS,
    COLLECTION_STATS_FETCH_SUCCESS,
    COLLECTION_TAB_VALUE_SET,
    COLLECTION_TRANSFER_ADDRESS_SET,
    COLLECTION_TRANSFER_DIALOG_CONFIRMATION_SHOW,
    COLLECTION_TRANSFER_DIALOG_HIDE,
    COLLECTION_TRANSFER_DIALOG_SHOW, DENOM_SPLIT_INFO_SET, DENOM_SPLIT_INFO_STATUS_SET,
    DOWNLOAD_COLLECTION_NFT_S_ERROR,
    DOWNLOAD_COLLECTION_NFT_S_IN_PROGRESS,
    DOWNLOAD_COLLECTION_NFT_S_SUCCESS,
    JSON_TAB_SWITCH_SET,
    LISTED_FILTER_SET,
    LISTED_ON_SALE_IN_SEARCH_SET,
    LISTED_ON_SALE_IN_SET,
    LISTED_PRICE_RANGE_COIN_SET,
    LISTED_PRICE_RANGE_SET,
    LISTED_SEARCH_IN_PROGRESS_SET,
    LISTED_SEARCH_SET,
    NON_LISTED_SEARCH_IN_PROGRESS_SET,
    NON_LISTED_SEARCH_SET,
    NON_TRANSFERABLE_SEARCH_IN_PROGRESS_SET,
    NON_TRANSFERABLE_SEARCH_SET, UPDATE_COLLECTION_SET,
} from '../constants/collection';
import { tokensList } from '../utils/defaultOptions';
import {
    FETCH_LISTED_COLLECTION_NFT_S_ERROR,
    FETCH_LISTED_COLLECTION_NFT_S_SUCCESS,
    FETCH_NON_LISTED_COLLECTION_NFT_S_ERROR,
    FETCH_NON_LISTED_COLLECTION_NFT_S_SUCCESS,
    FETCH_NON_TRANSFERABLE_COLLECTION_NFT_S_ERROR,
    FETCH_NON_TRANSFERABLE_COLLECTION_NFT_S_SUCCESS,
} from '../constants/createAssets/mintNft';
import { DISCONNECT_SET } from '../constants/account';
import { VIDEO_DROP_FIELDS_CLEAR } from '../constants/videoDrops';

const tabValue = (state = 'all', action) => {
    if (action.type === COLLECTION_TAB_VALUE_SET) {
        return action.value;
    }

    return state;
};

const tabSwitch = (state = 'visual', action) => {
    if (action.type === JSON_TAB_SWITCH_SET) {
        return action.value;
    }

    return state;
};

const listedSearch = (state = {
    inProgress: false,
    value: '',
}, action) => {
    switch (action.type) {
    case LISTED_SEARCH_SET:
        return {
            ...state,
            value: action.value,
        };
    case LISTED_SEARCH_IN_PROGRESS_SET:
        return {
            ...state,
            inProgress: action.value,
        };
    case FETCH_LISTED_COLLECTION_NFT_S_SUCCESS:
    case FETCH_LISTED_COLLECTION_NFT_S_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const listedFilter = (state = {
    show: false,
}, action) => {
    switch (action.type) {
    case LISTED_FILTER_SET:
        return {
            show: action.value,
        };
    default:
        return state;
    }
};

const listedOnSaleIn = (state = {
    value: new Set(),
    search: '',
}, action) => {
    switch (action.type) {
    case LISTED_ON_SALE_IN_SET:
        return {
            ...state,
            value: action.value,
        };
    case LISTED_ON_SALE_IN_SEARCH_SET:
        return {
            ...state,
            search: action.value,
        };
    default:
        return state;
    }
};

const listedPriceRange = (state = {
    value: tokensList && tokensList.length && tokensList[0],
    range: [0, 50000],
    min: 0,
    max: 50000,
}, action) => {
    switch (action.type) {
    case LISTED_PRICE_RANGE_COIN_SET:
        return {
            ...state,
            value: action.value,
        };
    case LISTED_PRICE_RANGE_SET:
        return {
            ...state,
            range: action.value,
        };
    default:
        return state;
    }
};

const nonListedSearch = (state = {
    inProgress: false,
    value: '',
}, action) => {
    switch (action.type) {
    case NON_LISTED_SEARCH_SET:
        return {
            ...state,
            value: action.value,
        };
    case NON_LISTED_SEARCH_IN_PROGRESS_SET:
        return {
            ...state,
            inProgress: action.value,
        };
    case FETCH_NON_LISTED_COLLECTION_NFT_S_SUCCESS:
    case FETCH_NON_LISTED_COLLECTION_NFT_S_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const nonTransferableSearch = (state = {
    inProgress: false,
    value: '',
}, action) => {
    switch (action.type) {
    case NON_TRANSFERABLE_SEARCH_SET:
        return {
            ...state,
            value: action.value,
        };
    case NON_TRANSFERABLE_SEARCH_IN_PROGRESS_SET:
        return {
            ...state,
            inProgress: action.value,
        };
    case FETCH_NON_TRANSFERABLE_COLLECTION_NFT_S_SUCCESS:
    case FETCH_NON_TRANSFERABLE_COLLECTION_NFT_S_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const downloadCollectionNFTs = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case DOWNLOAD_COLLECTION_NFT_S_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case DOWNLOAD_COLLECTION_NFT_S_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case DOWNLOAD_COLLECTION_NFT_S_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

const collectionExportPopover = (state = {
    anchorEl: null,
    open: false,
}, action) => {
    switch (action.type) {
    case COLLECTION_EXPORT_POPOVER_SHOW:
        return {
            anchorEl: action.anchorEl,
            open: true,
        };
    case COLLECTION_EXPORT_POPOVER_HIDE:
    case DOWNLOAD_COLLECTION_NFT_S_SUCCESS:
    case DOWNLOAD_COLLECTION_NFT_S_ERROR:
        return {
            ...state,
            anchorEl: null,
            open: false,
        };
    default:
        return state;
    }
};

const collectionStats = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case COLLECTION_STATS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case COLLECTION_STATS_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case COLLECTION_STATS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const transferDialog = (state = {
    open: false,
    confirmation: false,
    value: '',
    address: '',
}, action) => {
    switch (action.type) {
    case COLLECTION_TRANSFER_DIALOG_SHOW:
        return {
            ...state,
            open: true,
            value: action.value,
        };
    case COLLECTION_TRANSFER_DIALOG_CONFIRMATION_SHOW:
        return {
            ...state,
            confirmation: true,
        };
    case COLLECTION_TRANSFER_DIALOG_HIDE:
        return {
            open: false,
            confirmation: false,
            value: '',
            address: '',
        };
    case COLLECTION_TRANSFER_ADDRESS_SET:
        return {
            ...state,
            address: action.value,
        };
    default:
        return state;
    }
};

const denomSplitInfo = (state = {
    status: false,
    value: [],
}, action) => {
    switch (action.type) {
    case DENOM_SPLIT_INFO_STATUS_SET: {
        if (action.value && state.value && Object.keys(state.value).length === 0) {
            return {
                status: action.value,
                value: [{
                    address: action.address,
                    weight: '100',
                }],
            };
        } else if (!action.value) {
            return {
                ...state,
                status: action.value,
                // value: [],
            };
        } else {
            return {
                ...state,
                status: action.value,
            };
        }
    }
    case DENOM_SPLIT_INFO_SET:
        return {
            ...state,
            value: action.value,
        };
    case UPDATE_COLLECTION_SET: {
        const array = [];
        if (action.value && action.value.royalty_receivers && action.value.royalty_receivers.length) {
            action.value.royalty_receivers.map((value) => {
                const obj = {};
                obj.address = value.address;
                obj.weight = Number(value.weight) * 100;

                array.push(obj);
                return null;
            });
        }

        return {
            ...state,
            status: !!(action.value && action.value.royalty_receivers && action.value.royalty_receivers.length),
            value: action.value && action.value.royalty_receivers && action.value.royalty_receivers.length
                ? array : [],
        };
    }
    // case LISTINGS_FETCH_SUCCESS:
    // case LIST_NFT_DIALOG_HIDE:
    // case BULK_LIST_DIALOG_HIDE:
    // case CONFIRM_BULK_LIST_DIALOG_HIDE:
    case VIDEO_DROP_FIELDS_CLEAR:
        return {
            status: false,
            value: [],
        };
    default:
        return state;
    }
};

export default combineReducers({
    tabValue,
    tabSwitch,
    listedSearch,
    listedFilter,
    listedOnSaleIn,
    listedPriceRange,
    nonListedSearch,
    nonTransferableSearch,
    downloadCollectionNFTs,
    collectionExportPopover,
    collectionStats,
    transferDialog,

    denomSplitInfo,
});
