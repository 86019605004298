import { MESSAGE_SHOW, SNACKBAR_HIDE } from '../constants/snackbar';
import {
    ADD_TWITTER_ACCOUNT_ERROR,
    // BALANCE_FETCH_ERROR,
    CONNECT_ACCOUNT_ERROR,
    CONNECT_BC_ACCOUNT_ERROR,
    PROFILE_DETAILS_FETCH_ERROR,
    PROTO_BUF_SIGN_ERROR,
    TX_HASH_FETCH_SUCCESS,
    TX_SIGN_AND_BROAD_CAST_ERROR,
    TX_SIGN_AND_BROAD_CAST_SUCCESS,
    VERIFY_ACCOUNT_ERROR,
    VERIFY_TWEET_ERROR,
} from '../constants/account';
import { CATEGORY_ADD_ERROR, CATEGORY_ADD_SUCCESS, CATEGORY_FETCH_ERROR } from '../constants/createAssets/category';
import {
    ASSET_ADD_ERROR,
    ASSET_ADD_SUCCESS,
    ASSET_CARDS_FETCH_ERROR,
    ASSET_DELETE_ERROR,
    ASSET_DELETE_SUCCESS,
    ASSET_FETCH_ERROR,
    ASSET_UPDATE_ERROR,
    ASSET_UPDATE_SUCCESS,
    ASSET_UPLOAD_ERROR,
    AVATAR_UPLOAD_ERROR,
    TAGS_FETCH_ERROR,
} from '../constants/createAssets';
import { ASSETS_FETCH_ERROR } from '../constants/library';
import {
    COLLECTION_FETCH_ERROR,
    MINT_NFT_ADD_ERROR,
    MINT_NFT_ERROR,
    NFT_FETCH_ERROR,
} from '../constants/createAssets/mintNft';
import { FAUCET_BALANCE_ADD_ERROR, FEE_GRANT_ALLOWANCE_ERROR, FEE_GRANT_ALLOWANCE_SUCCESS } from '../constants/faucet';
import { IBC_TX_IN_PROGRESS_SET } from '../constants/IBCTokens';
import {
    INTEGRATION_ADD_ERROR,
    INTERACTIVE_VIDEO_ADD_ERROR,
    INTERACTIVE_VIDEO_MINT_ENABLE_ERROR,
    INTERACTIVE_VIDEO_PUBLISH_DISABLE_ERROR,
    INTERACTIVE_VIDEO_PUBLISH_ENABLE_ERROR,
    INTERACTIVE_VIDEO_PUBLISH_ERROR,
    INTERACTIVE_VIDEO_UPDATE_ERROR,
    INTERACTIVE_VIDEO_UPDATE_SUCCESS,
    INTEGRATION_DELETE_ERROR,
} from '../constants/interactiveVideos/new';
import { DOWNLOAD_IV_STATS_ERROR } from '../constants/interactiveVideos/stats';
import { CHANNEL_ADD_ERROR, CHANNEL_UPDATE_ERROR } from '../constants/channels';
import { CONTRACT_SIGN_ERROR } from '../constants/contracts';

const snackbar = (state = {
    open: false,
    message: '',
    explorer: null,
}, action) => {
    switch (action.type) {
    case MESSAGE_SHOW:
    case INTERACTIVE_VIDEO_MINT_ENABLE_ERROR:
    case CONNECT_ACCOUNT_ERROR:// BC Account
    case CONNECT_BC_ACCOUNT_ERROR:
    case VERIFY_ACCOUNT_ERROR:
    case TX_SIGN_AND_BROAD_CAST_ERROR:
    case TX_SIGN_AND_BROAD_CAST_SUCCESS:
    case TX_HASH_FETCH_SUCCESS:
    case PROTO_BUF_SIGN_ERROR:
    case PROFILE_DETAILS_FETCH_ERROR:// Profile Details
    case ADD_TWITTER_ACCOUNT_ERROR:
    case VERIFY_TWEET_ERROR:
    case FAUCET_BALANCE_ADD_ERROR:
    case FEE_GRANT_ALLOWANCE_SUCCESS:
    case FEE_GRANT_ALLOWANCE_ERROR:
    case CATEGORY_ADD_ERROR:// asset category
    case CATEGORY_ADD_SUCCESS:
    case CATEGORY_FETCH_ERROR:
    case ASSET_ADD_ERROR:// assets
    case ASSET_ADD_SUCCESS:
    case ASSET_UPDATE_ERROR:
    case ASSET_UPDATE_SUCCESS:
    case ASSETS_FETCH_ERROR:
    case TAGS_FETCH_ERROR:
    case ASSET_FETCH_ERROR:
    case ASSET_DELETE_ERROR:
    case ASSET_DELETE_SUCCESS:
    case ASSET_CARDS_FETCH_ERROR:
    case ASSET_UPLOAD_ERROR:
    case COLLECTION_FETCH_ERROR:// mint nft
    case AVATAR_UPLOAD_ERROR:
    case NFT_FETCH_ERROR:
    case MINT_NFT_ADD_ERROR:
    case MINT_NFT_ERROR:
    case INTERACTIVE_VIDEO_UPDATE_SUCCESS: // IV
    case INTERACTIVE_VIDEO_UPDATE_ERROR:
    case INTERACTIVE_VIDEO_ADD_ERROR:
    case INTEGRATION_ADD_ERROR:
    case INTERACTIVE_VIDEO_PUBLISH_ERROR:
    case INTERACTIVE_VIDEO_PUBLISH_ENABLE_ERROR:
    case INTERACTIVE_VIDEO_PUBLISH_DISABLE_ERROR:
    case CHANNEL_UPDATE_ERROR:
    case INTEGRATION_DELETE_ERROR:
    case CHANNEL_ADD_ERROR:
    case DOWNLOAD_IV_STATS_ERROR:
    case CONTRACT_SIGN_ERROR: // contracts
        return {
            open: true,
            message: action.message,
            variant: action.variant,
            hash: action.hash,
            explorer: action.explorer ? action.explorer : null,
        };
    case IBC_TX_IN_PROGRESS_SET:
        if (action.type === false) {
            return {
                open: false,
                message: '',
                variant: '',
                hash: '',
                explorer: null,
            };
        }
        return state;
    case SNACKBAR_HIDE:
        return {
            open: false,
            message: '',
            variant: '',
            hash: '',
        };

    default:
        return state;
    }
};

export default snackbar;
